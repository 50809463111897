import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from "react-router";

import LoginForm from './LoginForm';

import Firebase from "./Firebase";
import { AuthContext } from "./FirebaseAuth";

import Header from './Header';

const Login = ({ history }) => {

    const onSubmit = useCallback(
        async event => {
            event.preventDefault();
            const { email, password } = event.target.elements;
            try {
                await Firebase.auth().signInWithEmailAndPassword(email.value, password.value);
                history.push("/");
            } catch (error) {
                alert(error);
            }
        },
        [history]
    );

    const auth = useContext(AuthContext);

    if (auth.currentUser) {
        return <Redirect to="/" />;
    }

    return (
        <div className="App">
            <Header isHome={true} />

            <section className="App-section App-connect">
                <p>
                    Bonjour veuillez vous connecter pour accéder à l'application
                </p>
                <LoginForm action={onSubmit} />
            </section>
        </div>
    )
}

export default withRouter(Login);