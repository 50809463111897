import React, { useContext, useState } from 'react';
import { AuthContext } from "../FirebaseAuth";
import * as Firestore from "../Firestore";
import { Redirect, useHistory } from "react-router-dom";
import { ABSOLU_CLEAN, ABSOLU_CLEAN_SERVICES } from "../../constants";
import { Button } from '../Button';
import Header from '../Header';

export const NewCustomer = () => {
    const auth = useContext(AuthContext);
    const [name, setName] = useState(null);
    const [deliveries, setDeliveries] = useState([]);
    const [type, setType] = useState(null);
    const history = useHistory();

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    const addCustomer = () => {
        if (name) {
            Firestore.addCustomer({
                name,
                deliveries,
                type,
                tasks: [],
                active: true
            }).then(function (docRef) {
                alert("Client créé avec succès");
                history.push("/admin/customers");
            }).catch(function (error) {
                console.error("Erreur lors de la création du client : ", error);
            });
        } else {
            alert("Veuillez renseigner le nom du client");
        }
    };

    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    const handleChangeDelivery = (e) => {
        setDeliveries([
            ...deliveries,
            e.target.name
        ]);
    };

    const handleClickType = (type) => {
        setType(type);
    };

    const week = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
    const checkboxWeekList = week.map(day => {
        return (
            <div key={day} className="form-group">
                <input
                    type="checkbox"
                    name={day}
                    id={`delivery${day}`}
                    onChange={(e) => handleChangeDelivery(e)}
                />
                <label htmlFor={`delivery${day}`}>{day}</label>
            </div>
        );
    });

    return (
        <div className="App">
            <Header />

            <section className="App-section App-admin">
                <div className="App-panel">
                    <h2>Ajout d'un nouveau client</h2>
                    <input
                        type="text"
                        name="name"
                        id="customerName"
                        placeholder="Nom du client"
                        onChange={(e) => handleChangeName(e)}
                    />
                    <p>
                        Choisir la catégorie du client
                </p>
                    <div className="container">
                        <figure
                            className={type === ABSOLU_CLEAN_SERVICES ? "disabled" : ""}
                            onClick={() => handleClickType(ABSOLU_CLEAN)}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/logo-${ABSOLU_CLEAN}.png`} alt="Prestation Absolu Clean" />
                        </figure>
                        <figure
                            className={type === ABSOLU_CLEAN ? "disabled" : ""}
                            onClick={() => handleClickType(ABSOLU_CLEAN_SERVICES)}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/logo-${ABSOLU_CLEAN_SERVICES}.png`} alt="Prestation Absolu Clean Services" />
                        </figure>
                    </div>

                    <div className="delivery-days">
                        <p>Interventions :</p>

                        {checkboxWeekList}
                    </div>

                    <Button
                        className="btn btn-lg btn-block btn-success bottom"
                        action={() => addCustomer()}
                    >
                        Enregistrer le client
                    </Button>
                </div>
            </section>
        </div>
    )
}
