import React from 'react'

const LoginForm = ({ action }) => {
    // state = {
    //     email: '',
    //     password: '',
    //     error: null,
    // };

    // onSubmit = event => {
    //     const { email, password } = this.state;

    //     this.props.firebase
    //         .doSignInWithEmailAndPassword(email, password)
    //         .then(() => {
    //             this.setState({ ...this.state });
    //             this.props.history.push("/itsok");
    //         })
    //         .catch(error => {
    //             this.setState({ error });
    //         });

    //     event.preventDefault();
    // };

    // onChange = event => {
    //     this.setState({ [event.target.name]: event.target.value });
    // };

    return (
        <form onSubmit={action}>
            <input
                name="email"
                type="email"
                placeholder="Email"
            />
            <input
                name="password"
                type="password"
                placeholder="Mot de passe"
            />
            <button className="btn btn-default" type="submit">
                Se connecter
            </button>

            {/* {error && <p>{error.message}</p>} */}
        </form>
    );
}

export default LoginForm;
