import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Login from './components/Login';
import Customer from './components/Customer';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './components/FirebaseAuth';
import { Delivery } from './components/Delivery';
import PrepareDelivery from './components/PrepareDelivery';
import { PostponeDelivery } from './components/PostponeDelivery';
import { Admin } from './components/admin/Admin';
import { AdminCustomers } from './components/admin/AdminCustomers';
import { AdminUsers } from './components/admin/AdminUsers';
import { NewCustomer } from './components/admin/NewCustomer';
import { NewUser } from './components/admin/NewUser';
import { EditCustomer } from './components/admin/EditCustomer';
// import { EditUser } from './components/admin/EditUser';

const Root = () => {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/admin" component={Admin} />
                    <Route exact path="/admin/customers" component={AdminCustomers} />
                    <Route exact path="/admin/new/customer" component={NewCustomer} />
                    <Route path="/admin/customer/:id" component={EditCustomer} />
                    <Route exact path="/admin/users" component={AdminUsers} />
                    <Route exact path="/admin/new/user" component={NewUser} />
                    {/* <Route path="/admin/user/:id" component={EditUser} /> */}
                    <Route path="/customer/:id" component={Customer} />
                    <Route path="/delivery/:id" component={Delivery} />
                    <Route path="/prepare/:id" component={PrepareDelivery} />
                    <Route path="/postpone/:id" component={PostponeDelivery} />
                </Switch>
            </Router>
        </AuthProvider>
    )
}

ReactDOM.render(
    <Root />,
    document.getElementById('root')
);

serviceWorker.unregister();
