import React, { useContext, useState } from 'react';
import { AuthContext } from "../FirebaseAuth";
import * as Firestore from "../Firestore";
import { Redirect, useHistory } from "react-router-dom";
import { Button } from '../Button';
import Header from '../Header';

export const NewUser = () => {
    const auth = useContext(AuthContext);
    const [user, setUser] = useState({});
    const history = useHistory();

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    const addUser = () => {
        if (user.email) {
            Firestore.addUser({
                ...user
            })

            history.push("/admin");
        } else {
            alert("Veuillez renseigner le nom de l'utilisateur");
        }
    };

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="App">
            <Header />

            <section className="App-section App-admin">
                <div className="App-panel">
                    <h2>Ajout d'un nouvel utilisateur</h2>
                    <input
                        type="text"
                        name="firstname"
                        id="UserFirstname"
                        placeholder="Prénom de l'utilisateur"
                        onChange={(e) => handleChange(e)}
                    />
                    <input
                        type="text"
                        name="lastname"
                        id="UserLastname"
                        placeholder="Nom de l'utilisateur"
                        onChange={(e) => handleChange(e)}
                    />
                    <input
                        type="email"
                        name="email"
                        id="UserEmail"
                        placeholder="Email de l'utilisateur"
                        onChange={(e) => handleChange(e)}
                    />
                    <input
                        type="password"
                        name="password"
                        id="UserPassword"
                        placeholder="Mot de passe de l'utilisateur"
                        onChange={(e) => handleChange(e)}
                    />

                    <Button
                        className="btn btn-lg btn-block btn-success bottom"
                        action={() => addUser()}
                    >
                        Ajouter l'utilisateur
                    </Button>
                </div>

            </section>
        </div>
    )
}
