import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../FirebaseAuth";
import * as Firestore from "../Firestore";
import { Redirect, useHistory } from "react-router-dom";
import { Button } from '../Button';
import { Switch } from '../Switch';
import Header from '../Header';

export const EditCustomer = (props) => {
    const auth = useContext(AuthContext);
    const [customer, setCustomer] = useState({});
    const [tasks, setTasks] = useState([]);
    const [taskToAdd, setTaskToAdd] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const getCustomer = async (id) => {

        Firestore.getCustomer(id)
            .then(doc => {
                if (doc.exists) {
                    setCustomer(doc.data());
                } else {
                    console.log("No such document!");
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
    }

    const getTasks = async (tasksToDisable) => {

        const data = [];
        Firestore.getTasks()
            .then(function (docs) {
                docs.forEach(doc => {
                    let item = { ...doc.data(), id: doc.id, disabled: false }

                    if (tasksToDisable.some(task => task.reference.id === item.id)) {
                        item.disabled = true;
                    }

                    data.push(item);
                });

                setTasks(data);
                setLoading(false);
            });
    }

    useEffect(() => {
        getCustomer(props.match.params.id);
    }, [props.match.params.id]);

    useEffect(() => {
        if (customer.tasks) {
            getTasks(customer.tasks);
        }
    }, [customer.tasks]);

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    if (loading) {
        return <div className="App"><p>Chargement en cours</p></div>
    }

    const editCustomer = (id) => {
        if (customer.name) {
            Firestore.editCustomer(id, customer)
                .then(function (docRef) {
                    alert("Client modifié avec succès");
                    history.push("/admin/customers");
                }).catch(function (error) {
                    console.error("Erreur lors de la modification du client : ", error);
                });
        } else {
            alert("Veuillez renseigner le nom du client");
        }
    };

    const handleChangeName = (e) => {
        setCustomer({
            ...customer,
            name: e.target.value
        });
    };

    const handleChangeDelivery = (day) => {
        const deliveries = [...customer.deliveries];
        if(deliveries.includes(day)) {
            deliveries.splice(deliveries.indexOf(day), 1)
        } else {
            deliveries.push(day);
        }
        setCustomer({
            ...customer,
            deliveries
        });
    };

    const changeCustomerState = () => {
        setCustomer({
            ...customer,
            active: !customer.active
        })
    }

    const addTaskToCustomer = () => {
        if (taskToAdd) {
            Firestore.addTaskToCustomer(props.match.params.id, taskToAdd)
                .then(function (docRef) {

                    const newTasks = tasks.filter(task => task.id !== taskToAdd);
                    const task = tasks.find(task => task.id === taskToAdd);
                    task.disabled = true;

                    newTasks.push(task);

                    setTasks(newTasks);
                    setTaskToAdd(null);

                }).catch(function (error) {
                    console.error("Erreur lors de l'ajout de la tâche : ", error);
                });
        } else {
            alert("Veuillez sélectionner une tâche avant l'ajout");
        }
    }

    const deleteTask = (id) => {
        Firestore.deleteTaskToCustomer(props.match.params.id, id)
            .then(() => {

                const newTasks = tasks.filter(task => task.id !== id);
                const task = tasks.find(task => task.id === id);
                task.disabled = false;

                newTasks.push(task);

                setTasks(newTasks);
            }).catch(error => {
                console.error("Erreur lors de la suppression de la tâche : ", error);
            });
    }

    const week = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
    const checkboxWeekList = week.map(day => {
        return (
            <div key={day} className="switch-group">
                <Switch
                    state={customer.deliveries.includes(day) ? true : false}
                    action={() => handleChangeDelivery(day)}
                />
                <span>{day}</span>
            </div>
        );
    });

    const listCustomerTasks = tasks.filter(task => task.disabled).map(task => {
        return (
            <div key={task.id} className="task-customer">
                <img
                    onClick={() => deleteTask(task.id)}
                    src={`${process.env.PUBLIC_URL}/img/criss-cross.svg`}
                    alt={`Supprimer la tâche ${task.name} du client ${customer.name}`}
                />
                <span>{task.name}</span>
            </div>
        );
    });

    const listTasks = tasks.filter(task => !task.unusual).map(task => {
        return (
            <option
                key={task.id}
                value={task.id}
                disabled={task.disabled}
            >
                {task.name}
            </option>
        );
    });

    const listMonthlyTasks = tasks.filter(task => task.unusual && task.frequency === "month").map(task => {
        return (
            <option
                key={task.id}
                value={task.id}
                disabled={task.disabled}
            >
                {task.name}
            </option>
        );
    });

    const listTrimesterTasks = tasks.filter(task => task.unusual && task.frequency === "trimester").map(task => {
        return (
            <option
                key={task.id}
                value={task.id}
                disabled={task.disabled}
            >
                {task.name}
            </option>
        );
    });

    return (
        <div className="App">
            <Header />

            <section className="App-section App-admin">

                <div className="App-panel">
                    <h2>Informations générales</h2>
                    <div className="state-customer">
                        <span>Etat du client : </span>
                        <Switch
                            state={customer.active}
                            action={() => changeCustomerState()}
                        />
                    </div>

                    <label htmlFor="customerName">Nom du client :</label>
                    <input
                        type="text"
                        name="name"
                        id="customerName"
                        placeholder="Nom du client"
                        value={customer.name}
                        onChange={(e) => handleChangeName(e)}
                    />
                </div>

                <div className="App-panel">
                    <h2>Gestion des tâches</h2>

                    <div className="customer-tasks">
                        {listCustomerTasks}
                    </div>

                    <div className="add-task">
                        <select
                            id="addTaskSelect"
                            onChange={(e) => setTaskToAdd(e.target.value)}
                            value={taskToAdd ? taskToAdd : ""}
                        >
                            <option value="">Sélection tâche à ajouter</option>
                            <optgroup label="Tâches récurrentes">
                                {listTasks}
                            </optgroup>
                            <optgroup label="Tâches ponctuelles mensuelles">
                                {listMonthlyTasks}
                            </optgroup>
                            <optgroup label="Tâches ponctuelles trimestrielles">
                                {listTrimesterTasks}
                            </optgroup>
                        </select>
                        <Button
                            className="btn btn-default"
                            action={() => addTaskToCustomer()}
                        >
                            Ajouter la tâche
                        </Button>
                    </div>
                </div>

                <div className="App-panel delivery-days">
                    <h2>Interventions</h2>

                    {checkboxWeekList}
                </div>

                <Button
                    className="btn btn-lg btn-block btn-success bottom"
                    action={() => editCustomer(props.match.params.id)}
                >
                    Modifier le client
                </Button>

            </section>
        </div>
    )
}
