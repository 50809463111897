import Firebase, { secondaryContext } from "./Firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const db = Firebase.firestore();
const customersRef = db.collection("customers");
const usersRef = db.collection("users");
const tasksRef = db.collection("tasks");
const postponeRef = db.collection("postpone");

export const getCustomers = () => {
    return customersRef.orderBy("name").get();
};

export const getCustomersByCompany = company => {
    return customersRef.where("type", "==", company).get();
};

export const getCustomer = id => {
    return customersRef.doc(id).get();
};

export const addCustomer = (item) => {
    return customersRef.add(item);
};

export const editCustomer = (id, item) => {

    // Liste des tâches mis à jour via addTaskToCustomer et deleteTaskToCustomer
    delete item.tasks;
    const customer = {
        ...item
    };

    return customersRef.doc(id).update(customer);
};

export const addTaskToCustomer = (customerId, taskId) => {
    const newTask = {
        reference: db.doc(`/tasks/${taskId}`),
        last: null
    };

    return customersRef.doc(customerId).update({
        tasks: firebase.firestore.FieldValue.arrayUnion(newTask)
    });
};

export const deleteTaskToCustomer = (customerId, taskId) => {
    const task = {
        reference: db.doc(`/tasks/${taskId}`),
        last: null
    };

    return customersRef.doc(customerId).update({
        tasks: firebase.firestore.FieldValue.arrayRemove(task)
    });
};

export const editTasksToCustomer = (customerId, items) => {

    const tasks = items.map(task => {
        return {
            reference: db.doc(`/tasks/${task.id}`),
            last: task.last
        }
    });

    return customersRef.doc(customerId).update({ tasks });
};

export const getTasks = () => {
    return tasksRef.orderBy("name").get();
};

export const getTasksByCustomer = async list => {
    
    return Promise.all(list.map(async id => {
        return await getTask(id);
    }));

};

export const getTask = id => {
    return tasksRef.doc(id).get();
};

export const addPostpone = (customerId, customerName, date, postponeDate) => {
    return postponeRef.add({
        reference: db.doc(`/customers/${customerId}`),
        customer: customerName,
        date,
        postponeDate
    });
};

export const getPostponeDeliveries = () => {
    return postponeRef.where("postponeDate", "<=", new Date().getTime()).get();
};

export const deletePostpone = (id) => {
    return postponeRef.doc(id).delete();
};

export const getUsers = () => {
    return usersRef.get();
};

export const getUser = id => {
    return usersRef.doc(id).get();
};

export const addUser = (user) => {
    secondaryContext.auth().createUserWithEmailAndPassword(user.email, user.password)
        .then(function (response) {
            console.log("Utilisateur " + response.user.uid + " créé avec succès !");
            secondaryContext.auth().signOut();

            usersRef.doc(response.user.uid).set({
                firstname: user.firstname,
                lastname: user.lastname
            });
        }).catch(error => {
            console.log(error);
        });
}
