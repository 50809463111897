import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import { AuthContext } from "./components/FirebaseAuth";
import * as Firestore from "./components/Firestore";
import { ABSOLU_CLEAN, ABSOLU_CLEAN_SERVICES } from "./constants";
import './App.css';
import Header from "./components/Header";
import { List } from "./components/List";

const App = () => {
  const auth = useContext(AuthContext);
  const [admin, setAdmin] = useState(false);
  const [company, setCompany] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [postponeDeliveries, setPostponeDeliveries] = useState([]);
  const [user, setUser] = useState({});
  const history = useHistory();

  const getCustomers = async (company) => {

    const data = [];
    Firestore.getCustomersByCompany(company)
      .then(function (docs) {
        docs.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id });
        });

        setCustomers(data);
      });
  }

  const getUser = async (id) => {

    Firestore.getUser(id)
      .then(doc => {
        if (doc.exists) {
          setUser(doc.data());
        } else {
          console.log("No such user!");
        }
      }).catch(function (error) {
        console.log("Error getting user:", error);
      });
  }

  const getPostponeDeliveries = async () => {

    Firestore.getPostponeDeliveries()
      .then(docs => {
        const data = [];

        docs.forEach(doc => {
          let item = doc.data();
          data.push({
            id: doc.id,
            customerId: item.reference.id,
            date: item.date,
            postponeDate: item.postponeDate,
            to: `prepare/${item.reference.id}`,
            label: item.customer
          });
        });

        setPostponeDeliveries(data);
      }).catch(function (error) {
        console.log("Error getting postpone deliveries:", error);
      });
  }

  useEffect(() => {
    if (auth.currentUser) {
      getUser(auth.currentUser.uid);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    if (company) {
      getCustomers(company);
    }
  }, [company]);

  useEffect(() => {
    if (auth.currentUser) {
      getPostponeDeliveries();
    }
  }, [auth.currentUser]);

  if (!auth.currentUser) {
    return <Redirect to="/login" />;
  } else if ((auth.currentUser.email === "fgirod@elorri.fr" || auth.currentUser.email === "heymanjf@absoluclean.fr") && !admin) {
    setAdmin(true);
  }

  const selectedCompany = (id) => {
    localStorage.setItem("company", id);
    setCompany(id);
  }

  const selectedCustomer = (id) => {
    auth.currentUser.updateProfile({ displayName: `${user.firstname} ${user.lastname}` });

    history.push(`/customer/${id}`);
  }

  // Sélection uniquement des clients dont une prestation est prévue pour le jour de la semaine
  const day = new Date().toLocaleDateString("fr-FR", { weekday: 'long' });
  const customersList = customers.filter(item => item.deliveries.includes(day)).map(item => (
    <option key={item.id} value={item.id}>
      {item.name}
    </option>
  ));

  return (
    <div className="App">
      <Header isHome={true} />

      <section className="App-section App-company-select">
        <div className="App-panel">
          <p>
            Choisir la nature de la prestation
          </p>
          <div className="container">
            <figure
              className={company === ABSOLU_CLEAN_SERVICES ? "disabled" : ""}
              onClick={() => selectedCompany(ABSOLU_CLEAN)}
            >
              <img src={`${process.env.PUBLIC_URL}/img/logo-${ABSOLU_CLEAN}.png`} alt="Prestation Absolu Clean" />
            </figure>
            <figure
              className={company === ABSOLU_CLEAN ? "disabled" : ""}
              onClick={() => selectedCompany(ABSOLU_CLEAN_SERVICES)}
            >
              <img src={`${process.env.PUBLIC_URL}/img/logo-${ABSOLU_CLEAN_SERVICES}.png`} alt="Prestation Absolu Clean Services" />
            </figure>
          </div>

          {company &&
            <select
              name="customers"
              id="customersList"
              onChange={(e) => selectedCustomer(e.target.value)}
            >
              <option value="">Sélection du client</option>
              {customersList}
            </select>
          }
        </div>

        {postponeDeliveries.length > 0 &&
          <div className="App-panel App-panel-warning">
            <h2>Prestations reportées à faire ce jour</h2>
            <List
              items={postponeDeliveries}
            />
          </div>
        }

        {admin &&
          <Link
            className="btn btn-lg btn-block btn-success bottom"
            to="/admin"
          >
            Administration
          </Link>
        }
      </section>

    </div >
  );
}

export default App;
