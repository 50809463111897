import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const config = {
    apiKey: "AIzaSyBoOM70yJsbPkgET5Q3YzNwqHCpdQxIzI0",
    authDomain: "absoluclean-26616.firebaseapp.com",
    databaseURL: "https://absoluclean-26616.firebaseio.com",
    projectId: "absoluclean-26616",
    storageBucket: "absoluclean-26616.appspot.com",
    messagingSenderId: "68203066307",
    appId: "1:68203066307:web:8ca0b24677b6b382690376"
};

const Firebase = firebase.initializeApp(config);

export const secondaryContext = firebase.initializeApp(config, "Secondary");

export default Firebase;