import React from 'react';
import { Link } from "react-router-dom";
import styles from './stylesheets/List.module.css';

export const List = ({ items }) => {

    const listItems = items.map(item => {
        return (
            <li key={item.to} className={styles.item}>
                <Link
                    to={{
                        pathname: item.to,
                        state: { ...item }
                    }}
                    className={styles.itemLink}
                >
                    {item.label}
                </Link>
                <span role="img" aria-label="">➡️</span>
            </li>
        );
    });

    return (
        <ul className={styles.container}>
            {listItems}
        </ul>
    )
}
