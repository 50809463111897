import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "./FirebaseAuth";
import { Button } from './Button';
import Header from './Header';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as Firestore from "./Firestore";

export const PostponeDelivery = (props) => {
    const auth = useContext(AuthContext);
    const company = localStorage.getItem("company");
    const [postponeDate, setPostponeDate] = useState(new Date());
    const [comment, setComment] = useState("");
    const history = useHistory();

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    const postponeDelivery = () => {
        if (comment) {
            Firestore.addPostpone(props.match.params.id, props.location.state.name, Date.now(), postponeDate.getTime())

            // Envoi du mail de fin de prestation !
            const formData = new FormData();
            formData.append('email', props.location.state.email);
            formData.append('subject', `Report de la prestation pour ${props.location.state.name}`);
            formData.append('message', `Report de la prestation prévue par ${auth.currentUser.displayName} à la date du ${postponeDate.getDay()}/${postponeDate.getMonth()}/${postponeDate.getFullYear()} pour le motif suivant : ${comment}`);

            fetch("https://www.absoluclean.fr/app-sendmail.php", {
                method: 'post',
                body: formData,
                mode: 'no-cors'
            });

            history.push("/");
        } else {
            alert("Un commentaire est requis pour justifier le report de la prestation");
        }
    }

    return (
        <div className="App">
            <Header customerType={company} />

            <section className="App-section App-customer">

                <div className="App-panel">
                    <h1>{props.location.state.name}</h1>

                    <Calendar
                        onChange={(date) => setPostponeDate(date)}
                        value={postponeDate}
                        minDate={postponeDate}
                    />

                    <div className="App-comment">
                        <textarea
                            cols="30"
                            rows="4"
                            placeholder="Commentaire sur le report de la prestation"
                            onChange={(e) => setComment(e.target.value)}
                        >
                            {comment}
                        </textarea>
                    </div>
                </div>

                <Button
                    className="btn btn-lg btn-block btn-warning bottom"
                    action={() => postponeDelivery()}
                >
                    Valider le report de la prestation
                </Button>

            </section>
        </div>
    )
}
