import React, { useContext } from 'react';
import { AuthContext } from "../FirebaseAuth";
import { Redirect } from "react-router-dom";
import { List } from '../List';
import Header from '../Header';

export const Admin = () => {
    const auth = useContext(AuthContext);

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <div className="App">
            <Header/>
            
            <section className="App-section App-admin">
                
                <List
                    items={[
                        { label: "Gestion des clients", to: "/admin/customers" },
                        { label: "Gestion des utilisateurs", to: "/admin/users" }
                    ]}
                />
            </section>
        </div>
    )
}
