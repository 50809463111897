import React, { useContext, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "./FirebaseAuth";
import * as Firestore from "./Firestore";

const PrepareDelivery = (props) => {

    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState({});
    const history = useHistory();

    const getCustomer = async (id) => {

        Firestore.getCustomer(id)
            .then(doc => {
                if (doc.exists) {
                    setCustomer(doc.data());
                    setLoading(false);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
    }

    const deletePostpone = async (id) => {
        Firestore.deletePostpone(id);
    }

    useEffect(() => {
        getCustomer(props.match.params.id);
    }, [props.match.params.id]);

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    if (!loading) {
        // Suppression du report
        deletePostpone(props.location.state.id);

        const tasks = customer.tasks.map(task => {
            return { id: task.reference.id, last: task.last }
        });

        // Envoi mail début de la prestation reportée
        const date = new Date(props.location.state.date);
        const startDate = new Date();
        const formData = new FormData();
        formData.append('email', customer.contact.email);
        formData.append('subject', `Démarrage de la prestation reportée pour ${customer.name}`);
        formData.append('message', `La prestation initialement prévue le ${date.getDay()}/${date.getMonth()}/${date.getFullYear()} vient de démarrer par ${auth.currentUser.displayName} à ${startDate.getHours()}h${startDate.getMinutes()}`);

        fetch("https://www.absolu-clean.com/app-sendmail.php", {
            method: 'post',
            body: formData,
            mode: 'no-cors'
        });

        history.push(`/delivery/${props.match.params.id}`, { 
            name: customer.name,
            email: customer.contact.email,
            tasks
        });
    }

    return (
        <div></div>
    )
}

export default PrepareDelivery;