import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../FirebaseAuth";
import * as Firestore from "../Firestore";
import { Redirect, Link } from "react-router-dom";
import { List } from '../List';
import Header from '../Header';
import { ABSOLU_CLEAN, ABSOLU_CLEAN_SERVICES } from "../../constants";

export const AdminCustomers = () => {
    const auth = useContext(AuthContext);
    const [customers, setCustomers] = useState([]);

    const getCustomers = async () => {

        const data = [];
        Firestore.getCustomers()
            .then(function (docs) {
                docs.forEach(doc => {
                    let customer = doc.data();
                    data.push({ to: `/admin/customer/${doc.id}`, label: customer.name, type: customer.type });
                });

                setCustomers(data);
            });
    }

    useEffect(() => {
        getCustomers();
    }, []);

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    const customersACList = customers.filter(item => item.type === ABSOLU_CLEAN);
    const customersACSList = customers.filter(item => item.type === ABSOLU_CLEAN_SERVICES);

    return (
        <div className="App">
            <Header />

            <section className="App-section App-admin">

                <div className="App-panel">
                    <figure className="App-admin-logo">
                        <img src={`${process.env.PUBLIC_URL}/img/logo-${ABSOLU_CLEAN}.png`} alt="Clients Absolu Clean" />
                    </figure>
                    <List
                        items={customersACList}
                    />
                </div>

                <div className="App-panel">
                    <figure className="App-admin-logo">
                        <img src={`${process.env.PUBLIC_URL}/img/logo-${ABSOLU_CLEAN_SERVICES}.png`} alt="Clients Absolu Clean Services" />
                    </figure>
                    <List
                        items={customersACSList}
                    />
                </div>

                <Link
                    className="btn btn-lg btn-block btn-success bottom"
                    to="/admin/new/customer"
                >
                    Ajout d'un client
                </Link>
            </section>
        </div>
    )
}
