import React, { useContext, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "./FirebaseAuth";
import * as Firestore from "./Firestore";
import { Task } from "./Task";
import { Button } from "./Button";
import Header from "./Header";
import { ABSOLU_CLEAN } from "../constants";

export const Delivery = (props) => {

    const auth = useContext(AuthContext);
    const company = localStorage.getItem("company");
    const [loading, setLoading] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [comment, setComment] = useState("");
    const [file, setFile] = useState(null);
    const history = useHistory();

    const getTasks = async (tasks) => {

        const listTasks = tasks.map(task => task.id);

        const data = [];
        Firestore.getTasksByCustomer(listTasks)
            .then(function (docs) {
                docs.forEach(doc => {
                    // Date de la dernière intervention
                    let last = tasks.find(task => task.id === doc.id).last;

                    data.push({ ...doc.data(), id: doc.id, complete: false, last });
                });

                setTasks(data);
                setLoading(false);

            });
    }

    useEffect(() => {
        getTasks(props.location.state.tasks);
    }, [props.location.state.tasks]);

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    if (loading) {
        return <div className="App"><p>Chargement en cours</p></div>
    }

    const endDelivery = () => {
        const completedTasks = tasks.filter(task => task.complete);

        if (completedTasks.length > 0) {

            // Màj de l'information task.last à Firebase
            Firestore.editTasksToCustomer(props.match.params.id, tasks)

            // Envoi du mail de fin de prestation !
            const endDate = new Date();
            const formData = new FormData();
            formData.append('email', auth.currentUser.email);
            formData.append('subject', `Fin de la prestation pour ${props.location.state.name}`);
            formData.append('message', `Fin de la prestation par ${auth.currentUser.displayName} à ${endDate.getHours()}h${endDate.getMinutes()}`);
            if (comment) {
                formData.append('comment', comment);
            }
            if (file) {
                formData.append('file', file);
            }

            let listCompleteTasks = "<ul>";
            completedTasks.forEach(task => {
                listCompleteTasks += `<li>${task.name}</li>`
            });
            listCompleteTasks += "</ul>";
            formData.append('tasks', listCompleteTasks);

            fetch("https://www.absoluclean.fr/app-sendmail.php", {
                method: 'post',
                body: formData,
                mode: 'no-cors'
            });

            history.push("/");
        } else {
            alert("Au moins une tâche doit être réalisée pour pouvoir envoyer le rapport de prestation");
        }
    }

    const completeTask = item => {
        let newTasks = [...tasks];
        let index = newTasks.indexOf(item);
        let task = { ...newTasks[index] };

        task.complete = !task.complete;
        task.last = Date.now();

        newTasks[index] = task;

        setTasks(newTasks);
    }

    const listTasks = tasks.filter(task => !task.unusual).map(task => {
        return (
            <Task
                key={task.id}
                id={task.id}
                name={task.name}
                complete={task.complete}
                action={() => completeTask(task)}
            />
        );
    });

    const listUnusualTasks = tasks.filter(task => task.unusual).map(task => {
        let days = null;

        if (task.last) {

            days = Math.floor((Date.now() - task.last) / (1000 * 60 * 60 * 24));

        }

        let name = task.name
        if (days) {
            let goal = task.frequency === "month" ? 30 : 120;

            name += (goal - days) === 1 ? " à faire aujourd'hui" : ` à faire avant ${goal - days} jours`
        }

        return (
            <Task
                key={task.id}
                id={task.id}
                name={name}
                complete={task.complete}
                action={() => completeTask(task)}
            />
        );
    });

    const displayUnusualTasks = () => {
        if (listUnusualTasks.length > 0) {
            return (
                <div className="unusual-tasks">
                    <div className="unusual-tasks-label">
                        <img src={`${process.env.PUBLIC_URL}/img/alert.svg`} alt="" />
                        <p className="text-warning">Tâches ponctuelles <small>(cocher la case si tâche réalisée ce jour)</small></p>

                    </div>

                    {listUnusualTasks}
                </div>
            )
        }
    }

    return (
        <div className="App">
            <Header customerType={company} />

            <section className="App-section App-customer">

                <div className="App-panel">
                    <h1>{props.location.state.name}</h1>

                    {listTasks}

                    {displayUnusualTasks()}

                    <div className="App-comment">
                        <textarea
                            cols="30"
                            rows="6"
                            placeholder="Commentaire sur la prestation"
                            onChange={(e) => setComment(e.target.value)}
                        >
                            {comment}
                        </textarea>

                        <input
                            type="file"
                            className="inputfile"
                            id="file"
                            onChange={(e) => { setFile(e.target.files[0]) }}
                        />
                        <label htmlFor="file" className="btn">
                            <img src={`${process.env.PUBLIC_URL}/img/upload.svg`} alt="" />
                            { file ? "1 photo joint à votre commentaire" : "Joindre une photo à votre commentaire"}
                        </label>
                    </div>
                </div>

                <Button
                    className="btn btn-lg btn-block btn-danger bottom"
                    action={() => endDelivery()}
                >
                    {company === ABSOLU_CLEAN ? "Envoyer le rapport" : "Fin de la prestation"}
                </Button>

            </section>
        </div>
    )
}
