import React from 'react';

import styles from './stylesheets/Header.module.css';
import { useHistory } from 'react-router-dom';

const Header = ({ isHome = false, customerType = "ac" }) => {
    const history = useHistory();
    
    return (
        <div className={`${styles.container} ${styles.fixed}`}>
            { !isHome && <img src={`${process.env.PUBLIC_URL}/img/previous.svg`} onClick={() => history.goBack()} className={styles.previous} alt="Revenir à l'écran précédent"/> }
            <img src={`${process.env.PUBLIC_URL}/img/logo-${customerType}-small.png`} className={styles.logo} alt="Absolu Clean" onClick={() => history.push("/")} />
        </div>
    )
}

export default Header
