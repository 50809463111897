import React, { useContext, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "./FirebaseAuth";
import * as Firestore from "./Firestore";
import { Button } from "./Button";
import { ABSOLU_CLEAN } from "../constants"
import Header from "./Header";

const Customer = (props) => {

    const auth = useContext(AuthContext);
    const company = localStorage.getItem("company");
    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState({});
    const history = useHistory();

    const getCustomer = async (id) => {

        Firestore.getCustomer(id)
            .then(doc => {
                if (doc.exists) {
                    setCustomer(doc.data());
                    setLoading(false);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
    }

    useEffect(() => {
        getCustomer(props.match.params.id);
    }, [props.match.params.id]);

    const startDelivery = () => {
        const tasks = customer.tasks.map(task => {
            return { id: task.reference.id, last: task.last }
        });

        // Envoi mail début de la prestation
        const startDate = new Date();
        const formData = new FormData();
        formData.append('email', auth.currentUser.email);
        formData.append('subject', `Démarrage de la prestation pour ${customer.name}`);
        formData.append('message', `Début de la prestation par ${auth.currentUser.displayName} à ${startDate.getHours()}h${startDate.getMinutes()}`);

        fetch("https://elorri.fr/testmail", {
            method: 'post',
            body: formData,
            mode: 'no-cors'
        });

        history.push(`/delivery/${props.match.params.id}`, { name: customer.name, tasks });
    }

    const postponeDelivery = () => {
        history.push(`/postpone/${props.match.params.id}`, { name: customer.name });
    }

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    if (loading) {
        return <div className="App"><p>Chargement en cours</p></div>
    }

    return (
        <div className="App">
            <Header customerType={company} />

            <section className="App-section App-customer">
                <div className="App-panel">
                    <h1>{customer.name}</h1>
                    <Button
                        className="btn btn-lg btn-block btn-success"
                        action={() => startDelivery()}
                    >
                        { company === ABSOLU_CLEAN ? "Rapport de la prestation" : "Début de la prestation" }
                    </Button>
                    <Button 
                        className="btn btn-lg btn-block btn-warning"
                        action={() => postponeDelivery()}
                    >
                        Report de la prestation
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default Customer;