import React from 'react';
import './stylesheets/Switch.css';

export const Switch = ({ className, state, action }) => {
    let switchState = state ? "switch-checked" : ""; 

    return (
        <button
            type="button"
            role="switch"
            aria-checked={state}
            className={`switch ${switchState}`}
            onClick={action}
        >
            <div className="switch-handle"></div>
            <span className="switch-inner"></span>
        </button>
    )
}
