import React from 'react'

export const Task = ({ id, name, complete, action }) => {
    let textStyle = complete ? "complete" : "";

    return (
        <div className="task">
            <input
                type="checkbox"
                id={`task-${id}`}
                checked={complete}
                onChange={action}
            />
            <label className={textStyle} htmlFor={`task-${id}`}>{name}</label>
        </div>
    )
}
