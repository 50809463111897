import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../FirebaseAuth";
import * as Firestore from "../Firestore";
import { Redirect, Link } from "react-router-dom";
import { List } from '../List';
import Header from '../Header';

export const AdminUsers = () => {
    const auth = useContext(AuthContext);
    const [users, setUsers] = useState([]);

    const getUsers = async () => {

        const data = [];
        Firestore.getUsers()
            .then(function (docs) {
                docs.forEach(doc => {
                    let user = doc.data();
                    data.push({ to: `/admin/user/${doc.id}`, label: `${user.firstname} ${user.lastname}` });
                });

                setUsers(data);
            });
    }

    useEffect(() => {
        getUsers();
    }, []);

    if (!auth.currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <div className="App">
            <Header/>

            <section className="App-section App-admin">
                
                <List
                    items={users}
                />

                <Link
                    className="btn btn-lg btn-block btn-success bottom"
                    to="/admin/new/user"
                >
                    Ajout d'un utilisateur
                </Link>
            </section>
        </div>
    )
}
